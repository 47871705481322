import {
  Box,
  Button,
  ControlLabel,
  Flex,
  FormHeading,
  Modal,
  PanelFooter,
  PanelHeader,
  Select,
  Text,
} from "@oaktyres/ui";
import React, { createContext, useContext, useState } from "react";

export type TyreShopSettings = {
  costPrice: "inc" | "ex";
  sellOut: "default" | "inc" | "ex";
  defaultSort: "default" | "price";
};

type VatModalProps = {
  value: TyreShopSettings;
  onChange: (newValue: TyreShopSettings) => void;
  onClose: () => void;
};

const defaultTyreShopSettings = (): TyreShopSettings => ({
  costPrice: "ex",
  sellOut: "default",
  defaultSort: "default",
});

const loadTyreShopSettings = (): TyreShopSettings => {
  const fromStorage = window.localStorage.getItem("oak_vat");

  if (fromStorage == null) {
    return defaultTyreShopSettings();
  } else {
    return JSON.parse(fromStorage);
  }
};

type ContextValue = readonly [
  TyreShopSettings,
  (value: TyreShopSettings) => void,
];

export const TyreShopSettingsContext = createContext([
  defaultTyreShopSettings(),
  () => {},
] as ContextValue) as React.Context<ContextValue>;

export const TyreShopSettingsProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [val, set] = useState<TyreShopSettings>(loadTyreShopSettings);

  const setTyreShopSettings = (value: TyreShopSettings) => {
    set(value);
    window.localStorage.setItem("oak_vat", JSON.stringify(value));
  };

  const returnVal = [val, setTyreShopSettings] as const;

  return (
    <TyreShopSettingsContext.Provider value={returnVal}>
      {children}
    </TyreShopSettingsContext.Provider>
  );
};

export const useTyreShopSettings = () => useContext(TyreShopSettingsContext);

export const VatModal = ({ value, onChange, onClose }: VatModalProps) => {
  const reset = () => {
    onChange(defaultTyreShopSettings());
  };

  return (
    <Modal shown onClose={onClose} p={0} width={360}>
      <PanelHeader p={3}>
        <Text fontSize={3}>Settings</Text>
      </PanelHeader>
      <Box p={3}>
        <FormHeading mt={0}>Sorting</FormHeading>
        <Flex justifyContent="space-between" alignItems={"center"} mb={5}>
          <ControlLabel mb={0}>Default Tyre Sort</ControlLabel>
          <Select
            options={[
              { value: "default", label: "Default" },
              { value: "price", label: "Price" },
            ]}
            width={160}
            value={value.defaultSort}
            onChange={(ev) =>
              onChange({ ...value, defaultSort: ev.target.value as any })
            }
          />
        </Flex>
        <FormHeading mt={0}>VAT Settings</FormHeading>
        <Flex justifyContent="space-between" alignItems={"center"} mb={2}>
          <ControlLabel mb={0}>Cost Price</ControlLabel>
          <Select
            options={[
              { value: "ex", label: "Exclude" },
              { value: "inc", label: "Include" },
            ]}
            width={160}
            value={value.costPrice}
            onChange={(ev) =>
              onChange({ ...value, costPrice: ev.target.value as any })
            }
          />
        </Flex>
        <Flex justifyContent="space-between" alignItems="center">
          <ControlLabel mb={0}>Sell-Out Price</ControlLabel>
          <Select
            options={[
              { value: "default", label: "List Default" },
              { value: "ex", label: "Exclude" },
              { value: "inc", label: "Include" },
            ]}
            width={160}
            value={value.sellOut}
            onChange={(ev) =>
              onChange({ ...value, sellOut: ev.target.value as any })
            }
          />
        </Flex>
      </Box>
      <PanelFooter justifyContent="flex-end" p={3}>
        <Button onClick={reset} color="grey1">
          Reset
        </Button>
        <Button onClick={onClose} color="primary" ml={2}>
          Close
        </Button>
      </PanelFooter>
    </Modal>
  );
};
