import {
  Flex,
  IconButton,
  Label,
  LinkButton,
  ManufacturerLabel,
  PopOver,
  SortableTable,
  Text,
} from "@oaktyres/ui";
import React, { useMemo } from "react";
import gtIcon from "../../img/gt.svg";
import winterSrc from "../../img/winter.svg";

import { ImprestItemDto, TyreSearchPayload } from "@oaktyres/model";
import { SortableTableColumn } from "@oaktyres/ui/dist/components/SortableTable";
import { useCallback } from "react";
import styled from "styled-components";
import allSeasonSrc from "../../img/allseason.svg";
import summerSrc from "../../img/summer.svg";
import { FaMinus, FaPlus, FaRetweet } from "react-icons/fa";
import { useTyreShopSettings } from "./VatModal";

const rowStyle = (x: StockTableItem) => ({
  backgroundColor: x.alternativeFor
    ? "rgba(0,0,200, 0.1)"
    : x.productGroup === "Z1"
      ? "rgba(255,0,0,0.2)"
      : "white",
});

const seasonSrc = {
  W: winterSrc,
  S: summerSrc,
  A: allSeasonSrc,
};

const seasons = {
  W: "Winter",
  S: "Summer",
  A: "All-Season",
};

const fuelEfficiencyColors: Record<string, string> = {
  A: "#00A651",
  B: "#9ACA3C",
  C: "#FFF200",
  D: "#FDB913",
  E: "#ED1C24",
};

const wetGripColors: Record<string, string> = {
  A: "#0066B3",
  B: "#0080C6",
  C: "#4BA6DD",
  D: "#75BEE9",
  E: "#ABE1FA",
};

export type StockTableItem = (
  | TyreSearchPayload
  | (TyreSearchPayload & ImprestItemDto)
) & {
  side?: "f" | "r";
  qtyInBasket?: number;
  alternativeFor?: string;
};

export type FeatureProps = {
  id: string;
  title: string;
  content: string;
  color: string;
};

const Feature = ({ id, title, content, color }: FeatureProps) => (
  <>
    <PopOver target={id} content={title} noIcon small />
    <Label id={id} small color={color}>
      {content.replace("Technology", "")}
    </Label>
  </>
);

const GroupTyreIcon = styled.img`
  margin-right: 6px;
  height: 10px;
  display: block;
`;

const SeasonIcon = styled.img`
  width: 24px;
  height: 24px;
  border-radius: 3px;
  display: block;
  margin: auto;
`;
const flags: (keyof TyreSearchPayload)[] = [
  "bsw",
  "owl",
  "rwl",
  "wsw",
  "rbl",
  "ww",
];
const flagColors = [null, "#222", "#222", "#222", null, "#222"];
const flagNames = [
  "Black Sidewall",
  "Outlined White Letters",
  "Raised White Letters",
  "White Sidewall",
  "Raised Black Letters",
  "Whitewall",
];

const formatFitmentShort = (item: StockTableItem) => {
  const sections = [
    `${item.sizePrefix || ""}${item.section}/${item.profile}`,
    `${item.construction || ""}${item.rim} ${item.commercialSuffix || ""}`,
  ];

  return sections.filter(Boolean).join(" ");
};

type StockTableProps = {
  data: StockTableItem[];
  sellOutId?: string | null;
  sellOutIncVat?: boolean;
  onStockCodeClick: (
    code: string,
    index: number,
    self: TyreSearchPayload[],
  ) => void;
  onAddToBasket?: (code: string, count: number) => void;
  features?: TyreTableFeature[];
  emptyMessage?: string;
  basketHeading?: string;
  defaultSort?: number;
  defaultDesc?: boolean;
  fullRowClick?: boolean;
  disableIncrementalRender?: boolean;
  onShowAlternatives?: (stockCode: string) => void;
  restrictQuantityToStock?: boolean;
  alternatives?: Record<
    string,
    {
      loading: boolean;
      data: TyreSearchPayload[];
    }
  >;
};

export type TyreTableFeature =
  | "fitment"
  | "load"
  | "speed"
  | "season"
  | "brand"
  | "pattern"
  | "features"
  | "totalStock"
  | "fuelEfficiency"
  | "tyreNoise"
  | "wetGrip"
  | "price"
  | "sellOutPrice"
  | "quickAdd"
  | "stockCode"
  | "imprestQuantity";

const defaultFeatures: TyreTableFeature[] = [
  "fitment",
  "load",
  "speed",
  "season",
  "brand",
  "pattern",
  "features",
  "totalStock",
  "fuelEfficiency",
  "tyreNoise",
  "wetGrip",
  "price",
  "sellOutPrice",
];

export const getDefaultFeatures = () => [...defaultFeatures];

export const TyreTable = React.memo(
  ({
    data,
    sellOutId,
    sellOutIncVat,
    onStockCodeClick,
    onAddToBasket,
    emptyMessage,
    defaultSort,
    features = defaultFeatures,
    basketHeading = "In Basket",
    fullRowClick = true,
    defaultDesc = true,
    disableIncrementalRender = false,
    restrictQuantityToStock = false,
    onShowAlternatives,
    alternatives,
  }: StockTableProps) => {
    const hasPrices = useMemo(() => data.some((x) => x.price != null), [data]);
    const [tyreSettings] = useTyreShopSettings();

    const columns = useMemo<SortableTableColumn<StockTableItem>[]>(() => {
      const cols: SortableTableColumn<StockTableItem>[] = [
        {
          name: "stockCode",
          label: "Stock Code",
          format: (x, i, self) => (
            <Flex alignItems={"center"}>
              <LinkButton
                fontSize="inherit"
                fontFamily={"Roboto Mono"}
                onClick={() => onStockCodeClick(x.stockCode, i, self)}
              >
                {x.stockCode}
              </LinkButton>
              {x.alternativeFor && (
                <FaRetweet
                  style={{ marginLeft: 6 }}
                  title={`Alternative for ${x.alternativeFor}`}
                />
              )}
            </Flex>
          ),
        },
        {
          name: "fitment",
          label: "Fitment",
          format: (x: StockTableItem) => (
            <Flex alignItems="center" justifyContent="space-between">
              <Text fontSize="inherit">{formatFitmentShort(x)}</Text>
              {x.side === "f" ? (
                <Label ml={1} small color="positive">
                  Front
                </Label>
              ) : x.side === "r" ? (
                <Label ml={1} small color="warning">
                  Rear
                </Label>
              ) : null}
            </Flex>
          ),
          sortFormat: formatFitmentShort,
        },
        {
          name: "load",
          label: "Load",
          sortFormat: (x) => parseInt(x.load.split("/")[0], 10),
          format: (x) => (
            <Flex alignItems="center">
              {x.load}{" "}
              {x.loadIndexType !== "standard" &&
                x.loadIndexType !== "unknown" && (
                  <Label
                    labelSize="tiny"
                    ml={1}
                    color={x.loadIndexType === "high" ? "#333" : undefined}
                  >
                    {x.loadIndexType === "high" ? "HL" : "XL"}
                  </Label>
                )}
            </Flex>
          ),
        },
        {
          name: "speed",
          label: "SR",
          props: {
            textAlign: "center",
          },
          headingProps: {
            textAlign: "center",
          },
        },
        {
          name: "season",
          label: "Season",
          format: (x: StockTableItem) => {
            return x.season ? (
              <>
                <SeasonIcon
                  src={seasonSrc[x.season]}
                  alt={seasons[x.season]}
                  title={seasons[x.season]}
                />
              </>
            ) : null;
          },
        },
        {
          name: "brand",
          label: "Brand",
          sortFormat: (x) =>
            (x.brand.name ?? x.brand.crossReference).toLowerCase().trim(),
          format: (x) => {
            return (
              <ManufacturerLabel
                code={x.brand.crossReference}
                name={x.brand.name}
                icon={x.brand.icon}
                staticRoot={process.env.REACT_APP_STATIC_ROOT || ""}
              />
            );
          },
        },
        {
          name: "pattern",
          label: "Pattern",
          sortFormat: (x) =>
            (x.pattern.name ?? x.pattern.crossReference).toLowerCase().trim(),
          format: (x: TyreSearchPayload) => {
            return (
              <Flex alignItems="center" style={{ whiteSpace: "nowrap" }}>
                {x.pattern.name} {x.patternSuffix}
                {x.dot != null && (
                  <Label warning small ml={1}>
                    DOT {x.dot}
                  </Label>
                )}
                {x.oes.map((o, i) => (
                  <React.Fragment key={o.mark}>
                    <PopOver
                      target={x.stockCode + "oe" + i}
                      title={"OE Mark"}
                      noIcon
                      content={o.description}
                    />
                    <Label
                      color={"#" + o.color}
                      ml={1}
                      small
                      id={x.stockCode + "oe" + i}
                    >
                      {o.display}
                    </Label>
                  </React.Fragment>
                ))}
              </Flex>
            );
          },
        },
        {
          name: "features",
          label: "Features",
          notSortable: true,
          format: (x: TyreSearchPayload) => {
            return (
              <Flex justifyContent="center" alignItems="center">
                {(x.ply ?? 0) > 4 ? (
                  <Feature
                    title={x.ply + " ply"}
                    color="primary"
                    id={x.stockCode + "ply"}
                    content={x.ply + " ply"}
                  />
                ) : null}
                {x.runFlat ? (
                  <Feature
                    title="Run Flat"
                    color="positive"
                    id={x.stockCode + "runflat"}
                    content={x.runFlatTech || "Run Flat"}
                  />
                ) : null}
                {x.noiseCancelling ? (
                  <Feature
                    title="Noise Cancelling"
                    color="warning"
                    id={x.stockCode + "nc"}
                    content={x.noiseCancellingTech || "Noise Cancelling"}
                  />
                ) : null}
                {x.selfSealing ? (
                  <Feature
                    title="Self Sealing"
                    color="primary"
                    id={x.stockCode + "ss"}
                    content={x.selfSealingTech || "Self Sealing"}
                  />
                ) : null}
                {x.ev ? (
                  <Feature
                    title="Electric Vehicle Compatible"
                    color="#71e0e6"
                    id={x.stockCode + "ev"}
                    content={x.evTech || "EV"}
                  />
                ) : null}
                {flags.map((f, i) =>
                  x[f] ? (
                    <Feature
                      title={flagNames[i]}
                      color={flagColors[i] || "grey"}
                      id={x.stockCode + f}
                      content={f.toUpperCase()}
                    />
                  ) : null,
                )}
              </Flex>
            );
          },
        },
        {
          name: "totalStock",
          label: "Stk",
          format: (x) => {
            return (
              <Flex flexDirection={"column"} alignItems="center">
                {x.totalStock.toString()}
                {onShowAlternatives &&
                  "imprestQuantity" in x &&
                  x.totalStock < x.imprestQuantity &&
                  (alternatives?.[x.stockCode] == null ? (
                    <LinkButton onClick={() => onShowAlternatives(x.stockCode)}>
                      Show Alts.
                    </LinkButton>
                  ) : alternatives[x.stockCode].loading ? (
                    <Text fontSize={1} color="grey1">
                      Loading
                    </Text>
                  ) : alternatives[x.stockCode].data.length === 0 ? (
                    <Text fontSize={1} color="grey1">
                      None found
                    </Text>
                  ) : null)}
              </Flex>
            );
          },
          headingProps: {
            textAlign: "center",
          },
        },
        {
          name: "fuelEfficiency",
          label: "Fuel Eff",
          headingProps: {
            textAlign: "center",
          },
          format: (x) => (
            <Flex justifyContent={"center"}>
              <Label
                small
                style={{ width: 30, justifyContent: "center" }}
                color={fuelEfficiencyColors[x.fuelEfficiency]}
              >
                {x.fuelEfficiency}
              </Label>
            </Flex>
          ),
        },
        {
          name: "wetGrip",
          label: "Wet Grip",
          headingProps: {
            textAlign: "center",
          },
          format: (x) => (
            <Flex justifyContent={"center"}>
              <Label
                small
                style={{ width: 30, justifyContent: "center" }}
                color={wetGripColors[x.wetGrip]}
              >
                {x.wetGrip}
              </Label>
            </Flex>
          ),
        },
        {
          name: "tyreNoise",
          label: "Noise",
          headingProps: {
            textAlign: "center",
          },
          format: (x) => (
            <Flex justifyContent={"center"}>
              <Label
                small
                style={{ width: 30, justifyContent: "center" }}
                color={x.tyreNoise === 0 ? undefined : "grey3"}
              >
                {x.tyreNoise === 0 ? "" : x.tyreNoise}
              </Label>
            </Flex>
          ),
        },
        {
          name: "price",
          label: `Price ${
            tyreSettings.costPrice === "inc" ? "inc." : "ex."
          } VAT`,
          format: (x: TyreSearchPayload) => (
            <Flex justifyContent="flex-end" alignItems="center">
              {x.price?.isGroupPrice && (
                <>
                  <PopOver
                    target={`gt-${x.stockCode}`}
                    content={"Group Tyre Priced"}
                    small
                  />
                  <GroupTyreIcon src={gtIcon} id={`gt-${x.stockCode}`} />
                </>
              )}
              {x.price == null || x.price.value === 0 || x.price.value == null
                ? "N/A"
                : `£${(
                    x.price.value * (tyreSettings.costPrice === "inc" ? 1.2 : 1)
                  ).toFixed(2)}`}
            </Flex>
          ),
          sortFormat: (x: TyreSearchPayload) => x.price?.value ?? -1,
          numeric: true,
        },
        {
          name: "imprestQuantity",
          label: "Expected Qty",
          numeric: true,
        },
        {
          name: "sellOutPrice",
          hidden: !sellOutId,
          label: `Sell Out ${
            (
              tyreSettings.sellOut === "default"
                ? sellOutIncVat
                : tyreSettings.sellOut === "inc"
            )
              ? "inc."
              : "ex."
          } VAT`,
          format: (x) => {
            let val = x.sellouts?.find((x) => x.listId === sellOutId)?.value;
            if (val == null) {
              return "N/A";
            } else {
              if (tyreSettings.sellOut === "inc" && !sellOutIncVat) {
                val = val * 1.2;
              } else if (tyreSettings.sellOut === "ex" && sellOutIncVat) {
                val = val / 1.2;
              }
              return `£${val.toFixed(2)}`;
            }
          },
          numeric: true,
          sortFormat: (x) => {
            return x.sellouts?.find((x) => x.listId === sellOutId)?.value ?? 0;
          },
        },
        {
          name: "quickAdd",
          label: basketHeading,
          headingProps: {
            textAlign: "center",
          },
          props: {
            width: "100px",
          },
          format: (x) => (
            <Flex justifyContent={"center"}>
              <IconButton
                icon={FaMinus}
                disabled={(x.qtyInBasket ?? 0) < 1}
                onClick={
                  onAddToBasket
                    ? (ev) => {
                        ev.stopPropagation();
                        onAddToBasket(x.stockCode, -1);
                      }
                    : undefined
                }
              />
              <Text
                fontSize="inherit"
                width="30px"
                display={"inline-block"}
                textAlign="center"
              >
                {x.qtyInBasket}
              </Text>
              <IconButton
                icon={FaPlus}
                color="body"
                disabled={
                  restrictQuantityToStock &&
                  (x.qtyInBasket ?? 0) >= x.totalStock
                }
                onClick={
                  onAddToBasket
                    ? (ev) => {
                        ev.stopPropagation();
                        onAddToBasket(x.stockCode, 1);
                      }
                    : undefined
                }
              />
            </Flex>
          ),
        },
      ];

      return cols.filter((x) => features.includes(x.name as TyreTableFeature));
    }, [
      onStockCodeClick,
      hasPrices,
      sellOutId,
      sellOutIncVat,
      features,
      onAddToBasket,
      onShowAlternatives,
      restrictQuantityToStock,
      basketHeading,
      alternatives,
      tyreSettings,
    ]);

    const onRowClick = useCallback(
      (item: TyreSearchPayload, index: number, self: TyreSearchPayload[]) => {
        onStockCodeClick(item.stockCode, index, self);
      },
      [onStockCodeClick],
    );

    const afterSort = useCallback(
      (items: StockTableItem[]) => {
        if (alternatives == null) {
          return items;
        }

        return items.flatMap((x) => [
          x,
          ...(alternatives[x.stockCode]?.data ?? []).map((y) => ({
            ...y,
            alternativeFor: x.stockCode,
          })),
        ]);
      },
      [alternatives],
    );

    return (
      <SortableTable
        compact
        columns={columns}
        data={data}
        disableIncrementalRender={disableIncrementalRender}
        defaultSort={defaultSort ?? null}
        defaultDesc={defaultDesc}
        rowKey="stockCode"
        emptyMessage={emptyMessage ?? "No Results"}
        rowStyle={rowStyle}
        onRowClick={fullRowClick ? onRowClick : undefined}
        afterSort={afterSort}
      />
    );
  },
);
