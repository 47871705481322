import { lighten } from "polished";
import React from "react";
import { IconType } from "react-icons/lib";
import styled from "styled-components";
import { space, SpaceProps } from "styled-system";
import { Box } from "..";

export type AlertProps = {
  children: React.ReactNode;
  color?: string;
  icon?: IconType;
  size?: "small" | "normal";
} & SpaceProps;

const lightenValues: any = {
  warning: 0.38,
  danger: 0.38,
};

const getColor = (props: any) => props.theme.colors[props.color] || props.color;

const AlertElem = styled.div<SpaceProps>`
  border-radius: 5px;
  border: 1px solid ${getColor};
  background-color: ${(props) => lighten(0.3, getColor(props))};
  border-color: ${(props) => props.theme.colors[props.color ?? "body"]};
  color: ${(props) => props.theme.colors[props.color ?? "body"]};
  display: flex;
  align-items: stretch;
  font-weight: 500;
  padding: 0;
  background-color: ${(props) =>
    lighten(
      lightenValues[props.color ?? ""] ?? 0.5,
      props.theme.colors[props.color ?? "body"],
    )};
  ${space};
`;

const IconContainer = styled.div<{ size: "small" | "normal" }>`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 0 0 auto;

  & svg {
    flex: 0 0 auto;
  }
`;

export function Alert({
  children,
  icon: Icon,
  size = "normal",
  ...rest
}: AlertProps) {
  return (
    <AlertElem
      {...rest}
      p={size === "normal" ? 2 : 1}
      pl={size === "normal" ? 3 : 2}
      style={{ alignItems: size === "small" ? "center" : "stretch" }}
    >
      {Icon && (
        <IconContainer size={size}>
          <Icon fontSize={size === "normal" ? "2.5em" : "1em"} />
        </IconContainer>
      )}
      <Box ml={size === "small" ? 2 : 3}>{children}</Box>
    </AlertElem>
  );
}
