import React, { ReactElement } from "react";
import styled, { keyframes } from "styled-components";
import { display, DisplayProps, space, SpaceProps } from "styled-system";
import { lighten } from "polished";
import { FaSpinner } from "react-icons/fa";

export type IconButtonProps = React.HTMLAttributes<HTMLButtonElement> &
  SpaceProps &
  DisplayProps & {
    size?: string | null;
    color?: string;
    disabled?: boolean;
    loading?: boolean;
    icon: React.ComponentType<any>;
  };

const getColor = (props: any) =>
  props.theme.colors[props.color || "body"] || props.color || "inherit";

const ButtonContainer = styled.button<SpaceProps & DisplayProps>`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background: none;
  border: 0;
  color: ${(props) => lighten(0.3, getColor(props))};

  & > svg {
    fill: ${(props) => lighten(0.3, getColor(props))};
  }

  &:hover:enabled > svg {
    fill: ${(props) => getColor(props)};
  }

  &:active,
  &:focus {
    outline: 0;
  }

  &:hover {
    color: ${(props) => getColor(props)};
  }

  &:disabled {
    opacity: 0.5;
  }
  ${space}
  ${display};
`;

const rotate = keyframes`
  100% { transform: rotate(360deg); } 
`;
const Spinner = styled(FaSpinner)`
  animation: ${rotate} 1s infinite linear both;
`;

export default function IconButton({
  icon: Icon,
  size = "",
  color = "body",
  loading,
  ...rest
}: IconButtonProps): ReactElement | null {
  return (
    <ButtonContainer {...rest} color={color} type="button">
      {loading ? <Spinner /> : <Icon size={size} />}
    </ButtonContainer>
  );
}
