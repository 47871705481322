import { AccountDto } from "@oaktyres/model";
import { uniq } from "lodash";
import { Box, LinkButton, Text } from "@oaktyres/ui";
import React from "react";
import { useState } from "react";
import styled from "styled-components";
import { AddressSelectorModal } from "./AddressSelectorModal";
import { useSetDeliveryDeliveryAddress } from "@oaktyres/queries";

const Address = styled.address`
  white-space: pre;
  font-size: 14px;
`;

export type DeliveryAddressSelectorProps = {
  account: AccountDto | null;
  value: number | null;
  onChange: (value: number | null) => void;
};

export const DeliveryAddressSelector = ({
  account,
  value,
  onChange,
}: DeliveryAddressSelectorProps) => {
  const [showModal, setShowModal] = useState(false);

  const activeAddress =
    value != null
      ? account?.addresses.find((x) => x.id === value)
      : account?.addresses.find((x) => x.isDefault);

  const address =
    activeAddress == null
      ? [
          account?.deliveryAddress1,
          account?.deliveryAddress2,
          account?.deliveryAddress3,
          account?.deliveryAddress4,
          account?.deliveryAddress5,
          account?.deliveryPostCode,
        ]
      : [
          activeAddress.name,
          activeAddress.address1,
          activeAddress.address2,
          activeAddress.city,
          activeAddress.county,
          activeAddress.postcode,
        ];

  return (
    <>
      {showModal && (
        <AddressSelectorModal
          accountCode={account?.code ?? ""}
          options={account?.addresses ?? []}
          value={value}
          onChange={onChange}
          onClose={() => setShowModal(false)}
        />
      )}
      <Box mb={2}>
        <Text fontWeight={600} fontSize={1}>
          Delivery Address
          {(account?.addresses.length ?? 0) > 1 && (
            <LinkButton ml={1} onClick={() => setShowModal(true)}>
              Change
            </LinkButton>
          )}
        </Text>
        <Address>
          {uniq(address)
            .filter((x) => x != null && x.trim() !== "")
            .join("\n")}
        </Address>
      </Box>
    </>
  );
};
