import { Link } from "react-router-dom";
import styled from "styled-components";
import { SpaceProps } from "styled-system";
import React from "react";
import Flex from "./Flex";
import Box from "./Box";
import { LinkButton } from "./LinkButton";
import Text from "./Text";
import { IdentityDisplay } from "@oaktyres/model";
import UserProfileImage from "./UserProfileImage";

const StyledLink = styled(Link)`
  font-weight: 500;
`;

export type UserFlagProps = {
  user: IdentityDisplay | null;
  to?: string;
  onClick?: () => void;
} & SpaceProps;

export function UserFlag({ user, to, onClick, ...rest }: UserFlagProps) {
  return (
    <Flex alignItems="center" {...rest}>
      <UserProfileImage
        mr={2}
        profileImage={user?.profileImage}
        name={user?.name ?? "?"}
      />
      <Box>
        {to != null ? (
          <StyledLink to={to} onClick={onClick} style={{ lineHeight: 1 }}>
            {user?.name ?? "Unknown User"}
          </StyledLink>
        ) : onClick != null ? (
          <LinkButton onClick={onClick} style={{ lineHeight: 1 }}>
            {user?.name ?? "Unknown User"}
          </LinkButton>
        ) : (
          <Text fontWeight={500} lineHeight={1}>
            {user?.name ?? "Unknown User"}
          </Text>
        )}
        <Text fontSize="80%" color="grey2" lineHeight={1.2}>
          {user?.email ?? ""}
        </Text>
      </Box>
    </Flex>
  );
}
