import {
  PriceDto,
  ProductDto,
  TyreAvailability,
  TyreSearchPayload,
} from "@oaktyres/model";
import {
  getProductById,
  getTyreByStockCode,
  useProductsById,
  useTyres,
} from "@oaktyres/queries";
import axios from "axios";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { useRef } from "react";
import { useMemo } from "react";
import { useQuery } from "react-query";
import { useScopedAccount } from "../components/ScopedAccountProvider";

type BasketStorageItem = {
  code: string;
  qty: number;
};

export type BasketStorage = {
  items: BasketStorageItem[];
};

export type BasketItemCommon = {
  code: string;
  qty: number;
};

export type LoadingBasketItem = BasketItemCommon & {
  status: "added" | "loading" | "error";
};

export type LoadedBasketItem = BasketItemCommon & {
  status: "loaded";
  data: IStockItem;
};

export type IStockItem = {
  productId?: string;
  variantId?: string;
  stockCode: string;
  images: string[];
  name: string;
  fitment?: string;
  description: string;
  manufacturer: string;
  price: PriceDto | null;
  availability: TyreAvailability[];
  rewards?: {
    points: number;
    dsp: number;
    bdf: number;
  };
  type: "tyre" | "product";
};

const wallFlags: (keyof TyreSearchPayload)[] = [
  "bsw",
  "owl",
  "rwl",
  "wsw",
  "rbl",
  "ww",
];

export const formatFullFitment = (item: TyreSearchPayload): string =>
  [
    `${item.sizePrefix || ""}${item.section}/${item.profile}`,
    `${item.construction || ""}${item.rim}${item.commercialSuffix || ""}`,
    `${item.load}${item.speed}`,
    ...item.oes.map((x) => x.mark),
    item.runFlat ? item.runFlatTech || "RUN FLAT" : null,
    item.noiseCancellingTech,
    item.selfSealingTech,
    ...wallFlags.map((x) => (item[x] ? x.toUpperCase() : null)),
  ]
    .filter(Boolean)
    .join(" ");

const tyreToStockItem = (tyre: TyreSearchPayload): IStockItem => {
  return {
    stockCode: tyre.stockCode,
    images: tyre.pattern?.images ?? [],
    name: tyre.pattern?.name ?? "",
    fitment: formatFullFitment(tyre),
    description: "",
    manufacturer: tyre.brand.crossReference,
    price: tyre.price ?? null,
    availability: tyre.availability,
    type: "tyre",
    rewards: tyre.rewards,
  };
};

const productToStockItem = (
  product: ProductDto,
  stockCode: string,
): IStockItem => {
  const v = product.variants.find((x) => x.stockCode === stockCode)!;

  return {
    productId: product.id,
    variantId: v.id,
    stockCode: stockCode,
    images: [...v.images, ...product.images],
    name: product.name,
    description: product.description,
    manufacturer: v.manufacturer,
    price: v.price ?? null,
    availability: v.availability ?? [],
    type: "product",
  };
};

export type BasketItem = LoadingBasketItem | LoadedBasketItem;

export type UseBasketReturnType = {
  items: BasketItem[];
  accountCode: string | null;
  clearBasket: () => void;
  addToBasket: (code: string, qty: number) => boolean;
  updateItem: (code: string, qty: number) => void;
  refreshBasket: () => void;
};

const BasketContext = React.createContext<UseBasketReturnType>(null!);

const fromStorage = () => {
  const s: BasketStorage = JSON.parse(
    window.localStorage.getItem("portal-basket") ?? "null",
  ) ?? { items: [] };

  return s.items;
};

type DataType = ProductDto | TyreSearchPayload;

const isProduct = (x: DataType): x is ProductDto => "attributes" in x;

const getStock = (
  codes: string[],
  accountCode: string,
): Promise<IStockItem[]> => {
  return Promise.all(
    codes.map((x) =>
      axios
        .get<DataType>(`/api/v2/stock/${x}?accountCode=${accountCode}`)
        .then(({ data }) => data)
        .then((p) =>
          isProduct(p) ? productToStockItem(p, x) : tyreToStockItem(p),
        ),
    ),
  );
};

export function BasketProvider({ children }: { children: React.ReactNode }) {
  const [accountCode] = useScopedAccount();
  const [items, setItems] = useState<BasketStorageItem[]>(fromStorage());
  const lastDataRef = useRef<BasketItem[]>([]);
  const stockData = useQuery(
    ["stock", items.map((x) => x.code), accountCode],
    () =>
      getStock(
        items.map((x) => x.code),
        accountCode,
      ),
    {
      retryOnMount: true,
      refetchOnWindowFocus: true,
      enabled: accountCode !== "",
    },
  );

  const basketItems = useMemo(() => {
    const data = items.map<BasketItem>((x) => {
      const last = lastDataRef.current.find((o) => o.code === x.code);

      if (stockData.isLoading) {
        return (
          last ?? {
            ...x,
            status: "loading",
          }
        );
      }

      if (!stockData.isSuccess) {
        return {
          ...x,
          status: "error",
        };
      }

      const item = stockData.data.find((t) => x.code === t.stockCode);

      if (item == null) {
        return {
          ...x,
          status: "error",
        };
      }

      return {
        ...x,
        status: "loaded",
        data: item,
      };
    });
    lastDataRef.current = data;
    return data;
  }, [stockData, items]);

  useEffect(() => {
    const s = {
      items: items.map((x) => ({
        code: x.code,
        qty: x.qty,
      })),
    };

    window.localStorage.setItem("portal-basket", JSON.stringify(s));
  }, [items]);

  const clearBasket = useCallback(() => {
    setItems([]);
  }, []);

  const addToBasket = useCallback(
    (code: string, qty: number) => {
      setItems((old) => {
        if (old.some((x) => x.code === code)) {
          return old
            .map((x) =>
              x.code === code
                ? {
                    ...x,
                    qty: x.qty + qty,
                  }
                : x,
            )
            .filter((x) => x.qty > 0);
        } else if (qty > 0) {
          return [
            ...old,
            {
              code: code,
              qty: qty,
              status: "added",
            },
          ];
        } else {
          return old;
        }
      });
      return true;
    },
    [accountCode],
  );

  const updateItem = useCallback((code: string, qty: number) => {
    setItems((old) =>
      old
        .map((x) => ({
          ...x,
          qty: x.code === code ? qty : x.qty,
        }))
        .filter((x) => x.qty > 0),
    );
  }, []);

  const refreshBasket = useCallback(() => {
    stockData.refetch();
  }, [stockData.refetch]);

  const res = {
    items: basketItems,
    clearBasket,
    addToBasket,
    accountCode,
    updateItem,
    refreshBasket,
  };

  return (
    <BasketContext.Provider value={res}>{children}</BasketContext.Provider>
  );
}

export const useScopedBasket = () => useContext(BasketContext);
