import { IconType } from "react-icons/lib";
import React from "react";
import styled from "styled-components";
import { layout, LayoutProps, space, SpaceProps } from "styled-system";

type ContainerProps = SpaceProps &
  LayoutProps & {
    image?: string | null;
  };

const Container = styled.div<ContainerProps>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: ${(props) =>
    props.theme.colors[props.color || ""] ||
    props.color ||
    props.theme.colors.grey3};
  background-image: url("${(props) => props.image}");
  background-size: cover;
  background-position: center;
  color: white;
  flex: 0 0 auto;
  ${space};
  ${layout};
`;

export type CircleProps = {
  icon?: IconType | null;
  size?: "tiny" | "small" | "medium" | "large" | "xlarge" | "xxlarge" | number;
  color?: "primary" | "blue" | string;
  image?: string | null;
  children?: React.ReactNode;
  style?: React.CSSProperties;
  title?: string;
  id?: string;
} & SpaceProps;

const sizes = {
  tiny: 18,
  small: 24,
  medium: 32,
  large: 48,
  xlarge: 64,
  xxlarge: 120,
} as const;

const iconSizes = {
  tiny: "0.6em",
  small: "0.9em",
  medium: "1.2em",
  large: "1.5em",
  xlarge: "2em",
  xxlarge: "3em",
} as const;

export const Circle = ({
  size = "medium",
  icon: Icon = null,
  color = "grey3",
  image = null,
  id,
  title,
  children,
  ...rest
}: CircleProps) => {
  const width: number = typeof size === "number" ? size : sizes[size];
  const iconSize: string =
    typeof size === "number" ? `${size / 2}px` : iconSizes[size];

  return (
    <Container
      id={id}
      width={width}
      height={width}
      color={color}
      image={image}
      title={title}
      {...rest}
    >
      {Icon && <Icon size={iconSize} />}
      {children}
    </Container>
  );
};

export default Circle;
