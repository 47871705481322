import React from "react";
import styled, { css } from "styled-components";
import { Link } from "react-router-dom";
import {
  flexbox,
  space,
  FlexboxProps,
  SpaceProps,
  LayoutProps,
  layout,
} from "styled-system";
import { IconType } from "react-icons/lib/cjs";
import { lighten } from "polished";

type CommonProps = FlexboxProps &
  LayoutProps &
  SpaceProps & {
    block?: boolean;
    large?: boolean;
    small?: boolean;
    tiny?: boolean;
    disabled?: boolean;
    id?: string;
    color?: "primary" | "positive" | "warning" | "danger" | string;
  };

const common = css<CommonProps>`
  display: ${(props) => (props.block ? "flex" : "inline-flex")};
  padding: ${(props) =>
    props.small
      ? "3px 12px"
      : props.large
        ? "12px 24px"
        : props.tiny
          ? "1px"
          : "8px 12px"};
  margin-bottom: 0;
  font-weight: 400;
  line-height: 1.42857143;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-image: none;
  border: 1px solid transparent;
  border-radius: 4px;
  background-color: ${(props) => props.theme.colors[props.color || "primary"]};
  color: white;
  align-items: center;
  font-weight: 600;
  font-size: ${(props) => (props.large ? 16 : props.tiny ? 10 : 14)}px;
  justify-content: center;
  min-width: ${(props) => (props.tiny ? 60 : 120)}px;
  transition:
    background 0.3s ease,
    opacity 0.3s ease;
  flex: 0 0 auto;
  ${flexbox};
  ${space};
  ${layout};

  &[disabled] {
    opacity: 0.5;
  }

  svg {
    margin-left: 6px;
  }

  &:hover {
    color: white;
    background-color: ${(props) =>
      lighten(0.1, props.theme.colors[props.color || "primary"])};
    text-decoration: none;
  }

  &:focus,
  &:active {
    outline: 0;
  }
`;

const ButtonElem = styled.button<CommonProps>`
  ${common};
`;

const LinkElem = styled(Link)`
  ${common};
  text-decoration: none;
`;

type ButtonProps = CommonProps & {
  style?: React.CSSProperties;
  to?: string;
  icon?: IconType;
  className?: string;
  children?: React.ReactNode;
  type?: "button" | "submit" | "reset";
  onClick?: (
    event: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>,
  ) => void;
};

const Button = ({ to, icon: Icon, children, type, ...rest }: ButtonProps) =>
  to ? (
    <LinkElem to={to} {...rest}>
      {children} {Icon && <Icon />}
    </LinkElem>
  ) : (
    <ButtonElem type={type} {...rest}>
      {children} {Icon && <Icon />}
    </ButtonElem>
  );

Button.defaultProps = {
  color: "primary",
};

export default Button;
