import { Depot, VanRunDto } from "@oaktyres/model";
import axios from "axios";
import { useQuery } from "react-query";

const getVanRunsForAccount = (code: string) =>
  axios.get<VanRunDto[]>(`/api/v2/vanruns/${code}`).then(({ data }) => data);

export const useAccountVanRuns = (code: string) =>
  useQuery(["vanruns", code], () => getVanRunsForAccount(code));

export const getDepots = () =>
  axios.get<Depot[]>("/api/depots").then(({ data }) => data);

export const useDepots = () => useQuery(["depots"], getDepots);
