import { LoadIndexType, TyreSearchPayload } from "@oaktyres/model";
import { fromUnixTime, isToday, isTomorrow } from "date-fns";
import { groupBy, uniq } from "lodash";

export type FilterSet = {
  [index: string]: {
    [index: string]: (x: TyreSearchPayload) => boolean;
  };
};

const isCommercial = (x: TyreSearchPayload) =>
  ["C2", "C3"].includes(x.class || "") ||
  x.load.includes("/") ||
  x.commercialSuffix === "C" ||
  x.sizePrefix === "LT" ||
  (x.ply ?? 0) > 4;

const filters: FilterSet = {
  Availability: {
    Today: (x) =>
      x.availability.some(
        (x) =>
          !x.thirdParty && !x.orderedStock && isToday(fromUnixTime(x.dispatch)),
      ),
    "Next Van": (x) => x.availability.some((x) => x.isNextRun),
    "By Tomorrow": (x) =>
      x.availability.some(
        (x) =>
          !x.thirdParty &&
          !x.orderedStock &&
          (isToday(fromUnixTime(x.dispatch)) ||
            isTomorrow(fromUnixTime(x.dispatch))),
      ),
  },
  "Brand Tier": {
    Premium: (x) => x.brand.tier === 2,
    "Mid-Range": (x) => x.brand.tier === 1,
    Budget: (x) => x.brand.tier === 0,
    Unknown: (x) => x.brand.tier == null,
  },
  ["Load Index"]: {},
  Season: {
    Summer: (x) => x.season === "S",
    Winter: (x) => x.season === "W",
    "All-Season": (x) => x.season === "A",
  },
  "Run-Flat": {
    "Run-Flat": (x) => !!x.runFlat,
    "Not Run-Flat": (x) => !x.runFlat,
  },
  "3PMSF": {
    Yes: (x) => x.threePeaks === true,
    No: (x) => x.threePeaks !== true,
  },
  Terrain: {
    "All-Terrain": (x) => x.pattern.allTerrain === true,
    "Mud-Terrain": (x) => x.pattern.mudTerrain === true,
    "Highway Terrain": (x) =>
      x.pattern.allTerrain !== true && x.pattern.mudTerrain !== true,
  },
  "Track Use": {
    Yes: (x) => x.pattern.track === true,
    No: (x) => x.pattern.track !== true,
  },
  Trailer: {
    Yes: (x) => x.pattern.trailer === true,
    No: (x) => x.pattern.trailer === false,
    Unknown: (x) => x.pattern.trailer == null,
  },
  Directionality: {
    Directional: (x) => x.pattern.directional === true,
    Asymmetrical: (x) => x.pattern.asymmetrical === true,
    "Multi-Directional": (x) =>
      x.pattern.directional === false && x.pattern.asymmetrical === false,
    Unknown: (x) =>
      x.pattern.directional == null && x.pattern.asymmetrical == null,
  },
  Commercial: {
    Commercial: isCommercial,
    "Non-Commercial": (x) => !isCommercial(x),
  },
  "Noise Cancelling": {
    "Noise Cancelling": (x) => !!x.noiseCancelling,
    "Not Noise Cancelling": (x) => !x.noiseCancelling,
  },
  "Self-Sealing": {
    "Self-Sealing": (x) => !!x.selfSealing,
    "Not Self-Sealing": (x) => !x.selfSealing,
  },
};

export const buildFilterSetFromData = (
  data: TyreSearchPayload[],
): FilterSet => {
  const ff = { ...filters };

  const oeBrands = uniq(
    data.flatMap((x) => x.oes.flatMap((x) => x.brands.map((x) => x.name))),
  );

  ff.Homologations = Object.fromEntries(
    oeBrands.map((x) => [
      x,
      (f) => f.oes.some((o) => o.brands.map((x) => x.name).includes(x)),
    ]),
  );

  const loads = groupBy(data, (x) => x.load);

  const loadNames: Record<LoadIndexType, string> = {
    standard: "(Std Load)",
    extra: "(Extra Load)",
    high: "(High Load)",
    unknown: "",
  };

  ff["Load Index"] = Object.fromEntries(
    Object.values(loads).map((items) => [
      `${items[0].load} ${loadNames[items[0].loadIndexType]}`,
      (f: TyreSearchPayload) => f.load === items[0].load,
    ]),
  );

  return ff;
};
