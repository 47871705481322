import { TyreSearchPayload } from "@oaktyres/model";
import {
  getAlternativesForStockCode,
  useImprestForAccount,
} from "@oaktyres/queries";
import {
  Box,
  Button,
  Flex,
  Loader,
  MiniToggle,
  Panel,
  Text,
  TyreModal,
  useToasts,
} from "@oaktyres/ui";
import { clamp } from "lodash";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { FaCartPlus } from "react-icons/fa";
import { useScopedBasket } from "../../basket";
import { useScopedAccount } from "../../components/ScopedAccountProvider";
import { TyreTable, TyreTableFeature } from "../TyreStore/TyreTable";
import { OrderProposalModal } from "./OrderProposalModal";
import { StockCheckHelpModal } from "./StockCheckHelpModal";

const tableFeatures: TyreTableFeature[] = [
  "stockCode",
  "imprestQuantity",
  "fitment",
  "load",
  "speed",
  "season",
  "brand",
  "pattern",
  "features",
  "totalStock",
  "price",
  "sellOutPrice",
  "quickAdd",
];

export type OrderProposalItem = {
  item: TyreSearchPayload;
  qty: number;
};

export const ImprestStorePage = () => {
  const [accountCode] = useScopedAccount();
  const { addToBasket, items } = useScopedBasket();
  const toast = useToasts();
  const imprest = useImprestForAccount(accountCode);
  const [shownCode, setShownCode] = useState<string | null>(null);
  const [stockCheckMode, setStockCheckMode] = useState(false);
  const [localBasket, setLocalBasket] = useState<Record<string, number>>({});
  const [proposedOrder, setProposedOrder] = useState<
    OrderProposalItem[] | null
  >(null);
  const [showStockCheckModeHelp, setShowStockCheckHelp] = useState(false);
  const [alts, setAlts] = useState<
    Record<string, { loading: boolean; data: TyreSearchPayload[] }>
  >({});

  const tableData = useMemo(() => {
    if (imprest.data == null) {
      return [];
    } else {
      return imprest.data.map((x) => ({
        ...x,
        qtyInBasket: localBasket[x.stockCode] ?? 0,
      }));
    }
  }, [imprest.data, localBasket]);

  const tableAlts = useMemo(() => {
    return Object.fromEntries(
      Object.entries(alts).map((x) => {
        return [
          x[0],
          {
            loading: x[1].loading,
            data: x[1].data.map((s) => ({
              ...s,
              qtyInBasket: localBasket[s.stockCode] ?? 0,
            })),
          },
        ];
      }),
    );
  }, [alts, localBasket]);

  useEffect(() => {
    setLocalBasket({});
  }, [stockCheckMode]);

  const getAlternatives = useCallback((stockCode: string) => {
    setAlts((old) => ({
      ...old,
      [stockCode]: {
        loading: true,
        data: [],
      },
    })),
      getAlternativesForStockCode(stockCode, accountCode).then((data) =>
        setAlts((old) => ({
          ...old,
          [stockCode]: {
            loading: false,
            data,
          },
        })),
      );
  }, []);

  const addTyre = useCallback((code: string, count: number) => {
    setLocalBasket((old) => ({
      ...old,
      [code]: clamp((old[code] ?? 0) + count, 0, 40),
    }));
  }, []);

  const generateOrder = () => {
    if (imprest.data == null) {
      return;
    }

    let order: OrderProposalItem[] = [];

    if (stockCheckMode) {
      order = imprest.data
        .map((x) => ({
          item: x,
          qty: x.imprestQuantity - (localBasket[x.stockCode] ?? 0),
        }))
        .filter((x) => x.qty > 0)
        .filter((x) => x.item.totalStock >= x.qty);
    } else {
      order = imprest.data
        .concat(Object.values(alts).flatMap((x) => x.data) as any)
        .map((x) => ({
          item: x,
          qty: localBasket[x.stockCode] ?? 0,
        }))
        .filter((x) => x.qty > 0);
    }

    setProposedOrder(order);
  };

  const canOrder =
    stockCheckMode || Object.values(localBasket).some((x) => x > 0);

  const closeProp = (accepted: boolean) => {
    if (accepted) {
      proposedOrder?.forEach((x) => addToBasket(x.item.stockCode, x.qty));

      const count =
        proposedOrder?.map((x) => x.qty).reduce((acc, val) => acc + val) ?? 0;

      toast.push({
        icon: FaCartPlus,
        title: "Added to basket",
        content: `${count} items added to basket`,
      });

      setLocalBasket({});
    }

    setProposedOrder(null);
  };

  return (
    <Box width="100%">
      {shownCode && (
        <TyreModal
          stockCode={shownCode}
          onClose={() => setShownCode(null)}
          accountCode={accountCode}
          basketItems={items}
          onAddToBasket={(count) => addToBasket(shownCode, count)}
        />
      )}
      {proposedOrder && (
        <OrderProposalModal proposal={proposedOrder} onClose={closeProp} />
      )}
      {showStockCheckModeHelp && (
        <StockCheckHelpModal onClose={() => setShowStockCheckHelp(false)} />
      )}
      <Panel p={2} pl={[2, 2, 4]} mb={2}>
        <Flex
          justifyContent={"space-between"}
          alignItems={["stretch", "center"]}
          flexDirection={["column", "row"]}
        >
          <Text as="h1" fontSize={4} fontWeight={600} mb={[1, 0]}>
            Imprest Stock
          </Text>
          <Flex flexDirection={["column", "row"]}>
            <MiniToggle
              value={stockCheckMode}
              label={"Stock Check Mode"}
              onChange={setStockCheckMode}
              help={() => setShowStockCheckHelp(true)}
            />
            <Button
              onClick={generateOrder}
              color="primary"
              mt={[1, 0]}
              ml={[0, 3]}
              disabled={!canOrder}
            >
              Create Order
            </Button>
          </Flex>
        </Flex>
      </Panel>
      <Panel p={0}>
        {imprest.isSuccess ? (
          <TyreTable
            defaultDesc={false}
            emptyMessage="No Imprest on this Account"
            data={tableData}
            onStockCodeClick={setShownCode}
            features={tableFeatures}
            onAddToBasket={addTyre}
            basketHeading={stockCheckMode ? "In Stock" : "Required"}
            restrictQuantityToStock={!stockCheckMode}
            defaultSort={1}
            fullRowClick={false}
            onShowAlternatives={stockCheckMode ? undefined : getAlternatives}
            disableIncrementalRender
            alternatives={stockCheckMode ? undefined : tableAlts}
          />
        ) : (
          <Loader />
        )}
      </Panel>
    </Box>
  );
};
